.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
    transition: all 0.3s ease;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(255, 166, 0, 0.8),
        hsla(17, 100%, 50%, 0.8)   
    )!important;
}